import * as React from "react"

function AssetHeaderBackChevron(props) {
  return (
    <svg
      width={15}
      height={10}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.597c0-.204.078-.41.234-.566L4.031.234a.8.8 0 111.132 1.132L2.73 3.797h10.707a.8.8 0 110 1.6H2.731L5.163 7.83A.8.8 0 014.03 8.96L.234 5.163A.797.797 0 010 4.597z"
        fill="#000"
      />
    </svg>
  );
}

function AssetInputPopUpChevron(props) {
  return (
    <svg
      width={8}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.767 3.384a.79.79 0 01.162-.24L3.72.295C3.911.098 4.125 0 4.36 0a.827.827 0 01.333.073c.103.047.205.12.306.221l2.784 2.85A.703.703 0 018 3.668c0 .142-.035.27-.104.387a.778.778 0 01-.275.274.74.74 0 01-.936-.147L4.364 1.776 2.036 4.181a.764.764 0 01-.565.252.723.723 0 01-.38-.105.823.823 0 01-.274-.274.76.76 0 01-.1-.387c0-.103.017-.197.05-.283zm3.926 8.546a.784.784 0 01-.333.07c-.232 0-.446-.097-.642-.29L.93 8.86a.79.79 0 01-.163-.24.764.764 0 01-.05-.283.769.769 0 01.375-.661.723.723 0 01.379-.105c.211 0 .4.084.565.252l2.328 2.409 2.32-2.41a.748.748 0 011.212.132.73.73 0 01.104.383c0 .206-.072.38-.217.522L5 11.71c-.101.101-.203.175-.306.221z"
        fill="#000"
      />
    </svg>
  );
}

function AssetLogo(props) {
  return (
    <svg
      width={64}
      height={56}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.831 45.68c.55.616 1.546.06 1.284-.724-.47-1.406-.91-3.153-.91-4.769 0-4.104 3.174-7.738 8.7-10.16 5.526-2.422 13.663-3.934 19.744-5.012 6.081-1.078 9.908-1.494 11.725-1.669 1.483-.142 2.122-1.073 2.355-1.96a.989.989 0 011.473-.58c1.598.98 4.01 3.013 4.01 6.35 0 3.407-2.34 6.386-7.692 6.386-3.387 0-7.86-.042-11.247.166-3.387.208-8.143.915-12.25 2.5-5.937 2.292-7.035 5.844-6.408 9.573.248 1.472 1.107 3.001 2.245 4.268.566.632 1.583.023 1.266-.764-.388-.96-.682-1.985-.783-3.02-.348-3.57 2.401-5.377 5.257-6.426 2.632-.966 6.12-1.809 10.358-2.102 5.238-.362 10.13.17 13.108.17 2.242 0 3.455-.573 4.061-2.103.232-.586.976-.754 1.494-.395 1.162.803 2.379 2.233 2.379 4.81 0 3.312-3.324 5.773-7.808 5.773-6.438 0-14.764-1.757-14.764 2.086 0 4.016 6.72 5.498 6.72 6.698 0 .91-2.14 1.224-4.582 1.224C32.61 56 0 48.583 0 30.25 0 18.437 15.177 11.187 27.477 8.11c11.181-2.798 22.11-3.172 22.11-6.943 0-.728.45-1.167 1.41-1.167 2.514 0 8.205 4.458 8.205 10.167 0 4-1.538 6.576-8.654 8.927-5.77 1.906-14.926 2.74-22.452 4.968C13.033 28.525 11.038 36 11.038 39c0 2.346 1.102 4.779 2.793 6.68z"
        fill="#000"
      />
    </svg>
  );
}

export { AssetHeaderBackChevron, AssetInputPopUpChevron, AssetLogo }
